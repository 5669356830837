const CLASS_SUCCESS = 'success';
const CLASS_ERROR = 'error';
const CLASS_WARNING = 'warning';

export const convertServerStatus = (status) => {
  switch (status) {
    case 'finished':
      return 'Delivered';
    case 'rejected':
      return 'Rejected';
    case 'failed':
      return 'Failed';
    case 'in_progress':
      return 'In Progress';
    default:
      return status;
  }
};

export const convertServerStatusToClass = (status) => {
  switch (status) {
    case 'finished':
      return CLASS_SUCCESS;
    case 'rejected':
      return CLASS_WARNING;
    case 'failed':
      return CLASS_ERROR;
    case 'in_progress':
      return CLASS_SUCCESS;
    default:
      return CLASS_WARNING;
  }
};
